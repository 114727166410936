import * as SERVICE_TYPE from '../../constants/loginMixNextServiceType';
export default {
    mounted(){
        window.onpopstate = null;
        sessionStorage.removeItem('compNm')
        sessionStorage.removeItem('bizNo')
        sessionStorage.removeItem('compForm')
        // FC Code 삭제
        this.$partner_clearConsultantInfo();

        // 리다이렉션처리
        this.redirectionProcess();

        // 각종모드처리
        this.routeQueryProcess();
    },
    methods: {
        redirectionProcess(){
            const service = this.$route.query.service;
            if(service){
                if(SERVICE_TYPE.INSURANCE_INTRO==service){
                    this.$router.push('/services/intro/insurance')
                }
                else if(SERVICE_TYPE.TAX_INTRO==service){
                    this.$router.push('/services/intro/tax')
                }
            }
        },
        routeQueryProcess(){
            const isMobile = this.$isMobile();
            const mode = this.$route.query.mode;
            const bizNo = this.$store.state.cmnCompanyStore.companyInfo.bizNo;
            const compNm = this.$store.state.cmnCompanyStore.companyInfo.compNm;
            let isCorporation = false
            if(bizNo){
                isCorporation = this.$isCorporation(bizNo) ? true : false;
            }
            
            if(mode&&isMobile&&isCorporation){
                if('guideToPc'==mode){
                    const alertMessage = `<h3 style='text-align: center'>${compNm}님,<br/> 세금환급 및 4대보험<br/>절감 가능한 금액이 있습니다.</h3>` +
                        '<br/>법인 기업은 PC에서만<br/>예상 절감액 확인이 가능합니다.<br/>' +
                        '<br/>발송된 이메일을 확인해주세요'
                    this.$fnAlert(alertMessage)
                }
            }
        }
    }
}
