<template>
  <div class="area a_patent">
    <div class="top">
      <p>특허 분석</p>
    </div>
    <div class="cont">
      <PatentAnalysis_ReportService
          title="기업평가 리포트 서비스"
          description="기업의 보유기술 및 특허 가치, 기업가치 추정분석"
          placeholder="사업자번호를 입력하세요."
      />
      <PatentAnalysis_ReportService
          title="특허평가 리포트 서비스"
          description="개인 및 기업 특허의 보유기술 및 특허 가치 분석"
          placeholder="특허출원번호를 입력하세요."
          :service-ready="false"
      />
    </div>
  </div>
</template>

<script>
import PatentAnalysis_ReportService from "./PatentAnalysis_ReportService";

export default {
  components:{PatentAnalysis_ReportService},
  name: 'PatentAnalysis',
}
</script>
