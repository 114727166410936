<template>
  <div class="area num">
    <div class="top">
      <p>{{ today }} 기준</p>
    </div>
    <div class="cont">
      <dl>
        <dt><b>누적 조회수</b></dt>
        <dd><b>{{ access.toLocaleString() }} 회</b></dd>
      </dl>
      <dl>
        <dt><b>회원수</b></dt>
        <dd><b>{{ members.toLocaleString() }} 명</b></dd>
      </dl>
    </div>
  </div>
</template>

<script>

import countingService from '../../../service/countingService';
import customerService from '../../../service/allOkMng/customerService';

export default {
  name:'counter',  
  data(){
    return {
      access:0,
      members:0,
      today:null,
    }
  },
  beforeMount(){
    this.today = this.$fnFormatDate(new Date());
    this.fetchCount();    
  },  
  methods:{    
    fetchCount(){
      // 조회수
      countingService.fetchCount(this.$axios)
        .then(res => {
          if(res.success){
            this.access = res.contents;
          }
        });
      
      // 회원수
      customerService.fetchCustomerCount(this.$axios)
        .then(res => {
          if(res.success){
            this.members = res.contents;
          }
        });  
    }
  }
}
</script>
