<template>
  <div class="area mgmt">
    <div class="top">
      <p>경영관리</p>
    </div>
    <div class="cont">
      <nuxt-link to="" class="unit u1" @click.native="mixFnTax">
        <div class="txt">
          <p class="txt_blue">평균환급액 3,650만원</p>
          <h3>세금환급 검토 서비스</h3>
          <p class="txt_gray">사업자라면 누구나 예상환급액<br/> 실시간 조회부터 환급 신청 까지!</p>
        </div>
        <em class="btn_ico b_next"></em>
      </nuxt-link>
      <nuxt-link to="" class="unit u2" @click.native="mixFnInsurance">
        <div class="txt">
          <p class="txt_blue">연 평균 15% 절감</p>
          <h3>4대보험료 절감</h3>
          <p class="txt_gray">노무정비를 통해 근로자 1인당<br/> 연평균 48만원 매년 절감 가능</p>
        </div>
        <em class="btn_ico b_next"></em>
      </nuxt-link>
      <nuxt-link to="" event="" class="unit u3" @click.native="mixFnLoan">
        <div class="txt">
          <p class="txt_blue">정부정책자금, IP담보대출</p>
          <h3>기업대출</h3>
        </div>
        <em class="btn_ico b_next"></em>
      </nuxt-link>
      <nuxt-link to="" class="unit u4" @click.native="mixFnEmploy">
        <div class="txt">
          <p class="txt_blue">근로자1인당 최대 1200만원 지원</p>
          <h3>고용지원금</h3>
        </div>
        <em class="btn_ico b_next"></em>
      </nuxt-link>
    </div>
  </div>
</template>

<script>
import serviceMix from "../../../mixins/partner/serviceMix";
export default {
  mixins:[serviceMix],
  name:'AllOkService'
}
</script>
