import * as basePaths from '../constants/apiPathPrefix';

function fetchCount(axios, payload) {
    return axios.get(`${basePaths.api}/count`)
        .then(res => res.data)
        .catch(res => {success:false})
}

export default {
    fetchCount
}