var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"area"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"cont"},[_c('div',{staticClass:"group1"},[_c('div',{staticClass:"g_top"},[_c('h3',[_vm._v("\n          신청가능한 지원사업\n          "),_c('Tooltip',{attrs:{"type":"!","desc":"<p>신청가능한 지원사업의 개수와 예상지원금은 사용자가 선택한 관심분야의 지원사업을 합산한 내용으로, 실제 결과와 차이가 있을 수 있습니다.</p>"}})],1),_vm._v(" "),_c('div',{staticClass:"bx_search"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.keyword),expression:"keyword"}],staticClass:"ipt",attrs:{"type":"text","placeholder":"검색어를 입력해주세요.","disabled":(!_vm.auth.loggedIn || !_vm.auth.hasMatchingData)  ? 'disabled' : false},domProps:{"value":(_vm.keyword)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.fnSearch.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.keyword=$event.target.value}}}),_vm._v(" "),_c('button',{staticClass:"btn_ico b_search",on:{"click":_vm.fnSearch}},[_vm._v("검색버튼")])])]),_vm._v(" "),_c('div',{staticClass:"g_cont"},[_c('div',{staticClass:"m_box",style:({ cursor: _vm.auth.loggedIn ? 'pointer' : 'default' }),on:{"click":() => {
              if(_vm.auth.loggedIn){
                if(_vm.auth.hasMatchingData) _vm.$router.push('/services/support?code=SR01_A')
                else _vm.mixFnSupport()
              }
            }}},[_c('h4',[_vm._v("신청가능 지원사업")]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.bizCount))])]),_vm._v(" "),_c('div',{staticClass:"m_box",style:({ cursor: _vm.auth.loggedIn ? 'pointer' : 'default' }),on:{"click":function($event){if(_vm.auth.loggedIn){
                if(_vm.auth.hasMatchingData) _vm.$router.push('/services/support?code=SR01_A')
                else _vm.mixFnSupport()
              }}}},[_c('h4',[_vm._v("최대(예상) 지원금")]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.fundCount))])])])]),_vm._v(" "),_c('div',{staticClass:"group2"},[_c('div',{staticClass:"cover",class:{ show: (!_vm.auth.loggedIn || !_vm.auth.hasMatchingData) },style:(_vm.style.cover),domProps:{"innerHTML":_vm._s(_vm.desc)},on:{"click":()=>{if(_vm.auth.loggedIn && !_vm.auth.hasMatchingData) _vm.mixFnSupport()}}}),_vm._v(" "),_c('div',{staticClass:"unit u_chart"},[_c('div',{ref:"chart",staticStyle:{"width":"100%"}})]),_vm._v(" "),_c('div',{staticClass:"unit u_list"},[_c('h3',[_vm._v("인기 지원사업")]),_vm._v(" "),_c('div',{staticClass:"l_cont"},[_c('div',{staticClass:"item darkblue",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push('/services/support/popular?pId=p01')}}},[_c('h1',[_vm._v("1")]),_vm._v(" "),_vm._m(1)]),_vm._v(" "),_c('div',{staticClass:"item skyblue",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push('/services/support/popular?pId=p02')}}},[_c('h1',[_vm._v("2")]),_vm._v(" "),_vm._m(2)]),_vm._v(" "),_c('div',{staticClass:"item blue",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push('/services/support/popular?pId=p03')}}},[_c('h1',[_vm._v("3")]),_vm._v(" "),_vm._m(3)]),_vm._v(" "),_c('div',{staticClass:"item skyblue",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push('/services/support/popular?pId=p04')}}},[_c('h1',[_vm._v("4")]),_vm._v(" "),_vm._m(4)]),_vm._v(" "),_c('div',{staticClass:"item darkblue",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push('/services/support/popular?pId=p05')}}},[_c('h1',[_vm._v("5")]),_vm._v(" "),_vm._m(5)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top"},[_c('p',[_vm._v("지원사업 매칭")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"txt"},[_c('span',{staticStyle:{"border-color":"#4472C4","color":"black"}},[_vm._v("금융")]),_c('span',{staticClass:"ml5",staticStyle:{"border-color":"#92D050","color":"black"}},[_vm._v("창업")]),_vm._v(" "),_c('p',[_vm._v("2024년 중소벤처기업부 소관 중소기업 정책자금 융자계획 공고")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"txt"},[_c('span',{staticStyle:{"border-color":"#4472C4","color":"black"}},[_vm._v("금융")]),_vm._v(" "),_c('p',[_vm._v("신용&기술 보증기금 저금리 및 한도증액 대환 프로그램 개편 안내")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"txt"},[_c('span',{staticStyle:{"border-color":"#4472C4","color":"black"}},[_vm._v("금융")]),_c('span',{staticClass:"ml5",staticStyle:{"border-color":"#FFC000","color":"black"}},[_vm._v("경영")]),_vm._v(" "),_c('p',[_vm._v("제조업 성장기반 소공인특화자금 접수 공고")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"txt"},[_c('span',{staticStyle:{"border-color":"#FFC000","color":"black"}},[_vm._v("경영")]),_c('span',{staticClass:"ml5",staticStyle:{"border-color":"#C00000","color":"black"}},[_vm._v("인력")]),_vm._v(" "),_c('p',[_vm._v("청년고용연계자금 (2024년 중소벤처기업부 소관 소상공인 정책자금)")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"txt"},[_c('span',{staticStyle:{"border-color":"#4472C4","color":"black"}},[_vm._v("금융")]),_vm._v(" "),_c('p',[_vm._v("사업자 ‘법인세 및 종합소득세’ 환급 사업 공고")])])
}]

export { render, staticRenderFns }