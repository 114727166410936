<template>
  <div class="unit u_info">
    <dl>
      <dt><b>보유특허</b></dt>
      <dd>{{patnCnt}}</dd>
    </dl>
    <dl>
      <dt><b>특허가치</b></dt>
      <dd>{{patnValue}}</dd>
    </dl>
    <dl>
      <dt><b>기업가치추정</b></dt>
      <dd>{{compValue}}</dd>
    </dl>
    <button type="button" class="btn" @click="fnOpenMyPatentReport">기업평가 리포트 보기</button>
  </div>
</template>

<script>
import companyInfoService from "../../../service/commonAllOk/companyInfoService";
import my from "../../../layouts/my";
export default {
  data(){
    return{
      patnCnt:'-개',
      patnValue:'총 -억원',
      compValue:'-억원',
      valueType:{
        patnCnt:{
          unit:'개',
          defaultValue:'-개'
        },
        patnValue:{
          unit:'억원',
          defaultValue:'총 -억원',
        },
        compValue:{
          unit:'억원',
          defaultValue:'-억원',
        }
      }
    }
  },
  mounted() {
    if( this.$isLogin() ){
      this.fnFetchBizPatent();
    }
  },
  computed:{
    selectedMyCompBizNo(){
      return this.$store.getters["userStore/getMyComp"] ? this.$store.getters["userStore/getMyComp"].bizNo : null;
    },
  },
  methods:{
    fnGetValue(type, value){
      const {unit, defaultValue} = this.valueType[type];
      if(value) {
        return (value && value>0) ? value.toLocaleString()+unit : '0'+unit;
      }
      return defaultValue;
    },
    fnSetData(valueComp){
      const keys = ['patnCnt', 'patnValue', 'compValue'];
      keys.forEach( key => {
        this[key] = this.fnGetValue(key,valueComp[key]);
      })
    },
    fnFetchBizPatent(){
      const bizNo = this.$store.state.userStore.selectedMyCompBizNo;
      if(bizNo){
        let doFetchData = this.$store.state.userStore.myValuationedCompMap[bizNo] ? false : true;
        if ( doFetchData ) {
          companyInfoService.fetchCompanyInfo(this.$axios, bizNo)
              .then(res => {
                if ( res.success ){
                  const convertedData = this.$convertCompanyInfo(res.contents);
                  const payload={};
                  payload[bizNo]={};
                  payload[bizNo].patnValue = convertedData.patnValue;
                  payload[bizNo].patnCnt = convertedData.patnCnt;
                  payload[bizNo].compValue = convertedData.compValue;
                  this.$store.commit('userStore/UPDATE_MY_VALUATIONED_COMP_MAP', payload);
                  this.fnSetData(convertedData);
                }
              })
              .catch( err => {
                console.error(err);
              })
        }else{
          this.fnSetData(this.$store.state.userStore.myValuationedCompMap[bizNo]);
        }
      }
    },
    fnOpenMyPatentReport(){
      // 미인증 사용자
      if( ! this.$isLogin() ){
        this.$fnAlert('로그인 후 이용하실 수 있어요');
      }
      // 인증 사용자
      else{
        let hasPatn = false;
        const myComp = this.$store.getters["userStore/getMyComp"];
        if(myComp && myComp.bizNo){
          const valuedComp = this.$store.state.userStore.myValuationedCompMap[myComp.bizNo];
          if(valuedComp && valuedComp.patnCnt>0){
            hasPatn = true;
          }
        }

        if(hasPatn){
          this.$openPatentReport(myComp.bizNo);
        }
        // 보유 특허가 없거나 조회가 안되는 경우
        else {
          this.$fnAlert('평가에 필요한 보유특허가 없습니다.<br/> 보유특허가 있는 경우 유선으로 문의주세요');
        }
      }
    },
  },
  watch:{
    selectedMyCompBizNo(success){
      if(success){
        this.fnFetchBizPatent();
      }
    }
  }
}
</script>
