<template>
  <div class="area a_my">
    <div class="top">
      <p>내정보</p>
    </div>
    <client-only>
      <!-- 미인증 회원 -->
      <div v-if="!logined" class="cont">
        <button type="button" class="btn b_kakao h45" @click="fnLogin">카카오 계정으로 로그인</button>
      </div>
      <!-- 인증 회원 -->
      <div v-else class="cont">
        <div class="profile">
          <button class="icoMy" @click="fnMyInfo"></button>
          <p>안녕하세요<br/><b @click="fnMyInfo" style="font-size: 1.17rem;">{{userNm}}</b> 님</p>
          <button class="logout" @click="fnLogout()">로그아웃</button>
        </div>
        <button type="button" class="btn l_gray h40" @click="fnMyPage">마이페이지</button>
      </div>
    </client-only>
    <div class="area a_patent">
      <div class="cont">
        <PatentAnalysis_My />
      </div>
    </div>
  </div>
</template>

<script>
import * as loginServiceType from '../../../constants/loginMixNextServiceType'
import authMix from '../../../mixins/authMix';
import PatentAnalysis_My from "./PatentAnalysis_My";
export default {
  name:'MyInfo',
  mixins:[authMix],
  components:{PatentAnalysis_My},
  beforeMount() {
    this.$store.dispatch('userStore/fetchMe')
  },
  computed:{
    userNm(){
      if(this.$isLogin()){
        const myCompany = this.$store.getters["userStore/getMyComp"]
        if( myCompany ){
          return myCompany.compNm;
        } else {
          return this.$getUserInfo().userNm;
        }
      }
      return '';
    },
    logined(){
      return this.$isLogin() && this.$store.state.userStore.success;
    }
  },
  methods:{
    fnLogin(){
      // this.$router.push(`/services/socialLogin?addBizInfo=y&selectedService=${loginServiceType.LANDING_HOME}`);
      this.fnMixLogin();
    },
    fnLogout(){
      this.fnMixLogout();
    },
    fnRegist(){
      this.$router.push('signup/inputBizNo');
    },
    fnMyPage(){
      if(!this.$isLogin()){
        this.$router.push('/services/socialLogin?selectedService=mypage');
      }
      else if(this.$store.state.userStore.myCompList && this.$store.state.userStore.myCompList.length>0){
        const {bizNo, compNm} = this.$auth_MyComp();
        this.$auth_SaveBizNo(bizNo);
        this.$router.replace('/mypage/myInfo')
        }
      else{
        this.$fnAlert('사업자등록이 필요합니다', {
          name:'확인',
          link:`/services/inputBizNo?addBizInfo=y&selectedService=${loginServiceType.LANDING_HOME}`,
        });
      }
    },
    async fnMyInfo(){
      // const bizNo = this.$store.state.userStore.myCompList[0].bizNo;
      // const compNm = this.$store.state.userStore.myCompList[0].compNm;
      const {bizNo, compNm} = this.$auth_MyComp();
      this.$auth_SaveBizNo(bizNo);
      this.$router.replace('/mypage/myInfo')
    }
  },
}
</script>
