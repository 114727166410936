<template>
  <div class="area">
    <div class="top">
      <p>지원사업 매칭</p>
    </div>
    <div class="cont">
      <div class="group1">
        <div class="g_top">
          <h3>
            신청가능한 지원사업
            <Tooltip type="!" desc="<p>신청가능한 지원사업의 개수와 예상지원금은 사용자가 선택한 관심분야의 지원사업을 합산한 내용으로, 실제 결과와 차이가 있을 수 있습니다.</p>" />
          </h3>
          <div class="bx_search">
            <input type="text" class="ipt" placeholder="검색어를 입력해주세요." v-model="keyword" @keyup.enter="fnSearch" :disabled="(!auth.loggedIn || !auth.hasMatchingData)  ? 'disabled' : false" />
            <button class="btn_ico b_search" @click="fnSearch">검색버튼</button>
          </div>
        </div>
        <div class="g_cont">
          <div
            class="m_box"
            @click="
              () => {
                if(auth.loggedIn){
                  if(auth.hasMatchingData) $router.push('/services/support?code=SR01_A')
                  else mixFnSupport()
                }
              }
            "
            :style="{ cursor: auth.loggedIn ? 'pointer' : 'default' }">
            <h4>신청가능 지원사업</h4>
            <p>{{ bizCount }}</p>
          </div>
          <div
            class="m_box"
            @click="
              if(auth.loggedIn){
                  if(auth.hasMatchingData) $router.push('/services/support?code=SR01_A')
                  else mixFnSupport()
                }
            "
            :style="{ cursor: auth.loggedIn ? 'pointer' : 'default' }">
            <h4>최대(예상) 지원금</h4>
            <p>{{ fundCount }}</p>
          </div>
        </div>
      </div>
      <div class="group2">
        <div class="cover"
             :class="{ show: (!auth.loggedIn || !auth.hasMatchingData) }"
             :style="style.cover"
             @click="()=>{if(auth.loggedIn && !auth.hasMatchingData) mixFnSupport()}"
             v-html="desc"></div>
        <div class="unit u_chart">
          <div ref="chart" style="width: 100%"></div>
        </div>
        <div class="unit u_list">
          <h3>인기 지원사업</h3>
          <div class="l_cont">
            <div class="item darkblue" style="cursor: pointer" @click="$router.push('/services/support/popular?pId=p01')">
              <h1>1</h1>
              <div class="txt">
                <span style="border-color: #4472C4; color: black">금융</span><span class="ml5" style="border-color: #92D050; color: black">창업</span>
                <p>2024년 중소벤처기업부 소관 중소기업 정책자금 융자계획 공고</p>
              </div>
            </div>
            <div class="item skyblue" style="cursor: pointer" @click="$router.push('/services/support/popular?pId=p02')">
              <h1>2</h1>
              <div class="txt">
                <span style="border-color: #4472C4; color: black">금융</span>
                <p>신용&기술 보증기금 저금리 및 한도증액 대환 프로그램 개편 안내</p>
              </div>
            </div>
            <div class="item blue" style="cursor: pointer" @click="$router.push('/services/support/popular?pId=p03')">
              <h1>3</h1>
              <div class="txt">
                <span style="border-color: #4472C4; color: black">금융</span><span class="ml5" style="border-color: #FFC000; color: black">경영</span>
                <p>제조업 성장기반 소공인특화자금 접수 공고</p>
              </div>
            </div>
            <div class="item skyblue" style="cursor: pointer" @click="$router.push('/services/support/popular?pId=p04')">
              <h1>4</h1>
              <div class="txt">
                <span style="border-color: #FFC000; color: black">경영</span><span class="ml5" style="border-color: #C00000; color: black">인력</span>
                <p>청년고용연계자금 (2024년 중소벤처기업부 소관 소상공인 정책자금)</p>
              </div>
            </div>
            <div class="item darkblue" style="cursor: pointer" @click="$router.push('/services/support/popular?pId=p05')">
              <h1>5</h1>
              <div class="txt">
                <span style="border-color: #4472C4; color: black">금융</span>
                <p>사업자 ‘법인세 및 종합소득세’ 환급 사업 공고</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tooltip from '../../common/ifs/Tooltip';
import bizInfoService from '~/service/commonAllOk/bizInfoService';
import serviceMix from "../../../mixins/partner/serviceMix";

export default {
  name: 'BizMatching',
  mixins:[serviceMix],
  components: { Tooltip },
  data() {
    return {
      matchCnt: null,
      supportAmt: null,
      keyword: null,
      chart: null,
    };
  },
  computed: {
    myComp() {
      return this.$store.getters['userStore/getMyComp'];
    },
    auth(){
      let loggedIn = this.$store.state.userStore.success;
      let hasMatchingData = (this.myComp && this.myComp.extData && this.myComp.extData.interests && this.myComp.extData.compTypes) ? true: false;
      return { loggedIn, hasMatchingData }
    },
    style(){
      const cover = {
        cursor:this.auth.loggedIn ? 'pointer' : null
      }
      return {cover}
    },
    desc() {
      if (!this.auth.loggedIn) {
        return '매칭정보 입력하고<br />맞춤형 지원사업<br/>추천을 받아보세요';
      } else if (!this.auth.hasMatchingData) {
        // return '사업자정보를 설정하면<br /> 추천 지원사업을 볼 수 있어요';
        return '매칭정보 입력하고<br />맞춤형 지원사업<br/>추천을 받아보세요';
      }
    },
    bizCount() {
      if (this.myComp&&this.auth.loggedIn&&this.auth.hasMatchingData) {
        return this.matchCnt == null || this.matchCnt == undefined ? '-' : `${this.matchCnt || ''}개`;
      }
      return '???';
    },
    fundCount() {
      if (this.myComp&&this.auth.loggedIn&&this.auth.hasMatchingData) {
        return this.supportAmt == null || this.supportAmt == undefined ? '-' : `최대 ${this.supportAmt || ''}억원`;
      }
      return '???';
    },
  },

  methods: {
    async fnDrawChart() {
      if (!this.auth.hasMatchingData) {
        if (this.chart) return;
        this.chart = toastui.Chart.pieChart({
          el: this.$refs.chart,
          data: {
            series: [
              {
                name: '금융',
                data: 6.15,
              },
              {
                name: '인력',
                data: 5.1,
              },
              {
                name: '창업',
                data: 6.41,
              },
              {
                name: '기술',
                data: 17.54,
              },
              {
                name: '인증',
                data: 11.78,
              },
              {
                name: '경영',
                data: 28.14,
              },
              {
                name: '수출',
                data: 10.6,
              },
              {
                name: '내수',
                data: 14.27,
              },
            ],
          },
          options: {
            chart: {
              width: 'auto',
              height: 300,
            },
            exportMenu: {
              visible: false,
            },
            legend: {
              showCheckbox: false,
            },
            series: {
              radiusRange: {
                inner: '50%',
                outer: '100%',
              },
              dataLabels: {
                visible: true,
                pieSeriesName: {
                  visible: false,
                },
              },
            },
            theme: {
              series: {
                dataLabels: {
                  fontSize: 12,
                },
              },
              legend: {
                label: {
                  fontSize: 14,
                  fontWeight: 700,
                },
              },
            },
          },
        });
        return;
      }

      if (!this.$store.state.infoyouStore.code) await this.$store.dispatch('infoyouStore/fetchBizInfoCodes');
      const code = this.$store.state.infoyouStore.code;
      const comp = this.$store.getters['userStore/getMyComp'];
      const form = {
        region: comp.extData?.korAddr && this.$store.state.infoyouStore.regions.find(el => comp.extData.korAddr.includes(el)),
        supportRealm: comp.extData?.interests || [],
        supportTarget: comp.extData?.compTypes || [],
      };
      const { success, contents } = await bizInfoService.fetchBizInfoMatch(this.$axios, form);
      if (success) {
        const { matchCnt, supportAmt, supportRealms } = contents;
        this.matchCnt = matchCnt;
        this.supportAmt = supportAmt;

        if (this.chart) this.chart.destroy();
        this.chart = toastui.Chart.pieChart({
          el: this.$refs.chart,
          data: {
            series: supportRealms.map(el => {
              if (code.length != form.supportRealm.length && form.supportRealm.includes(el.supportRealmCd)) {
                return {
                  name: `${el.supportRealmCdNm}⭐`,
                  data: Number(el.supportRealmRatio),
                };
              } else {
                return {
                  name: el.supportRealmCdNm,
                  data: Number(el.supportRealmRatio),
                };
              }
            }),
          },
          options: {
            chart: {
              width: 'auto',
              height: 300,
            },
            exportMenu: {
              visible: false,
            },
            legend: {
              showCheckbox: false,
            },
            series: {
              radiusRange: {
                inner: '50%',
                outer: '100%',
              },
              dataLabels: {
                visible: true,
                pieSeriesName: {
                  visible: false,
                },
              },
            },
            theme: {
              series: {
                dataLabels: {
                  fontSize: 12,
                },
              },
              legend: {
                label: {
                  fontSize: 14,
                  fontWeight: 700,
                },
              },
            },
          },
        });

        this.chart.on('clickLegendLabel', data => {
          const supportRealmCd = supportRealms.find(el => data[0].label.includes(el.supportRealmCdNm)).supportRealmCd;
          const root = code.find(el => el.code == supportRealmCd);
          if (root) {
            this.$store.commit('infoyouStore/UPDATE', { form: {} });
            if (root.sub.length > 0) {
              this.$router.push(`/services/support?code=${root.sub[0].code}`);
            } else {
              this.$router.push(`/services/support?code=${root.code}`);
            }
          }
        });
      }
    },
    fnSearch() {
      if (!this.keyword) {
        this.$fnAlert('검색어를 입력해주세요.');
        return;
      }
      this.$store.commit('infoyouStore/UPDATE', { form: {} });
      this.$router.push(`/services/support?word=${this.keyword}`);
    },
  },

  mounted() {
    this.fnDrawChart();

    //
    if(this.auth.loggedIn){
      this.style.cover.cursor='pointer';
    }
  },

  watch: {
    myComp(nv, ov) {
      if(JSON.stringify(ov) != JSON.stringify(nv)) this.fnDrawChart();
    },
  },
};
</script>
